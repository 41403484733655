/* eslint-disable */
export default {
	NODE_ENV: 'staging',
	API_URL: 'https://staging.cloud.lr-lab.com/api-staging',
	PROTOCOL_DOCU_URL: 'https://staging.cloud.lr-lab.com/api-staging/protocol-documentation',
	GOOGLE_AUTH_URL: 'https://staging.cloud.lr-lab.com/api-staging/auth/google',
	MICROSOFT_AUTH_URL: 'https://staging.cloud.lr-lab.com/api-staging/auth/microsoft',
	UUID_URL: 'https://staging.occhio-controls.lr-lab.com',
	LR_DISTRIBUTION_URL: 'https://staging.distribution.lr-lab.com',
	LR_PRODUCTION_URL: 'https://staging.production.lr-lab.com',
	NXT_SERVICE_CLOUD_URL: 'https://staging.nxt-service-cloud.lr-lab.com',
	NXT_CLOUD_URL: 'https://staging.nxt.lr-lab.com',
	OCCHIO_ELECTRONICS_CLOUD_URL: 'https://staging.occhio-electronics.lr-lab.com',
	COOKIE_PREFIX: 'staging_lr_cloud'
}
/* eslint-enable */
