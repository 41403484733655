/* eslint-disable */
export default {
	NODE_ENV: 'development',
	API_URL: 'http://localhost:8080/api',
	PROTOCOL_DOCU_URL: 'http://localhost:8080/api/protocol-documentation',
	GOOGLE_AUTH_URL: 'http://localhost:8080/api/auth/google',
	MICROSOFT_AUTH_URL: 'http://localhost:8080/api/auth/microsoft',
	UUID_URL: 'http://localhost:8082',
	LR_DISTRIBUTION_URL: 'http://localhost:8082',
	LR_PRODUCTION_URL: 'http://localhost:8087',
	NXT_SERVICE_CLOUD_URL: 'http://localhost:8089',
	NXT_CLOUD_URL: 'http://localhost:8090',
	OCCHIO_ELECTRONICS_CLOUD_URL: 'http://localhost:8089',
	COOKIE_PREFIX: 'dev_lr_cloud'
}
/* eslint-enable */
